import { Card } from "~/components/ui/card";
import { Paragraph } from "~/components/ui/typography";
import type { Cta } from "~/types";
import { Icon } from "~/components/ui/icons";
import type { ImageProps } from "~/components/ui/image";

export type EventCardItem = {
	tagline: string;
	title: string;
	subtitle?: string;
	date: string;
	time?: string;
	location?: string;
	cta?: Cta;
	image?: ImageProps;
};

interface Props {
	item: EventCardItem;
}

export function EventCard({ item }: Props) {
	return (
		<Card href={item.cta?.url ?? ""} trackingPosition="event">
			{item.image && item.image?.src ? (
				<Card.Cover imgProps={item.image} />
			) : null}
			<Card.Content className="!px-5">
				<Card.ChipText>{item.tagline}</Card.ChipText>
				<Card.Title>{item.title}</Card.Title>
				{item.subtitle ? (
					<Card.Subtitle className="mb-4">{item.subtitle}</Card.Subtitle>
				) : null}
				<div className="mb-3 flex items-center gap-3">
					<Icon name="calendar" color="primary" />
					<Paragraph size="body-small" color="secondary">
						<span suppressHydrationWarning>{item.date}</span>
					</Paragraph>
				</div>
				{item.time ? (
					<div className="mb-3 flex items-center gap-3">
						<Icon name="clock" color="primary" />
						<Paragraph size="body-small" color="secondary">
							<span suppressHydrationWarning>{item.time}</span>
						</Paragraph>
					</div>
				) : null}
				{item.location ? (
					<div className="mb-3 flex items-center gap-3">
						<Icon name="location" color="primary" />
						<Paragraph size="body-small" color="secondary">
							{item.location}
						</Paragraph>
					</div>
				) : null}
			</Card.Content>
			{item.cta && item.cta.url ? (
				<Card.Extra className="!px-5">
					<Card.GhostArrowCTA customIcon={item.cta.iconRight}>
						{item.cta.title}
					</Card.GhostArrowCTA>
				</Card.Extra>
			) : null}
		</Card>
	);
}
